function getUrl() {
  let host = window.location.host;
  let url = '';
  switch (host) {
    case 'locahost':
      // 测试环境
      url = 'http://8.129.190.113:9013/admin'
      //url = 'https://api.lilikq.com/ncrm/'
      break;
    default:
      // 正式环境
      url = 'https://api.lilikq.com/ncrm/'
      break;
  }
  return url;
}
export default {

  method: 'post',

  baseURL: getUrl(),

  // 请求头信息
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    /* 'X-Requested-With': 'XMLHttpRequest', */
    'Access-Control-Allow-Origin': '*'
  },

  // 参数
  data: {},

  // 设置超时时间
  timeout: 1000000000,

  // 携带凭证
  withCredentials: true,

  // 返回数据类型
  responseType: 'json'

}
