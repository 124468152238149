import router from './router'
import moment from 'moment'
import { SAVEMINEPOINTUPV } from '@/http/interface'
import { getDevinceType } from '@/common/util'

router.beforeEach((to, from, next) => {
  next()
})

router.afterEach((to, from, failure) => {
  let ios = getDevinceType()
  let now = moment().format('x')
  let token = sessionStorage.getItem('token')
  console.log(to)
  if (!token) {
    sessionStorage.setItem('startTime', now)
  }

  let start = sessionStorage.getItem('startTime') || 0
  let stayTime = now - start
  let oParams = {}
  oParams.entryTime = start

  oParams.systemInfo = { os: ios }

  // 判断是进入还是离开
  if (to.path != from.path) {
    oParams.triggerType = 'entryStr'
    oParams.pageInfo = {
      pageUrl: to.path,
      url: to.fullPath,
    }
    SAVEMINEPOINTUPV(oParams)

    // 离开
    oParams.leaveTime = now
    oParams.stayTime = stayTime
    oParams.triggerType = 'leaveStr'
    oParams.pageInfo = {
      pageUrl: from.path,
      url: from.fullPath,
    }
    SAVEMINEPOINTUPV(oParams)
  }

  if (to.path == from.path) {
    oParams.triggerType = 'entryStr'
    oParams.pageInfo = {
      pageUrl: from.path,
      url: from.fullPath,
    }
    SAVEMINEPOINTUPV(oParams)
  }

  console.log('6666666666')
  document.title = to.meta

  // 上报完成后，重置数据
  sessionStorage.setItem('startTime', moment().format('x'))

})
