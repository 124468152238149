import axios from 'axios' // 注意先安装哦
import config from './config.js' // 倒入默认配置
import qs from 'qs' // 序列化请求数据，视服务端的要求
import { Util, getUrlParams } from '@/common/util'
axios.defaults.withCredentials = true

/* axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; */

export default function $axios(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: {},
      timeout: config.timeout,
      transformResponse: [function (data) { }],
    })

    /**
     * request 拦截器
     * 在发送请求之前做些什么
     */
    instance.interceptors.request.use(
      (config) => {
        //config.headers['Content-Type'] = 'application/json'; // 设置很关键
        /**
         * 是否展示loading，默认请求展示loading
         */
        /* if (!options.loading) {
          Util.loading();
        } */

        // 根据请求方法，序列化传来的参数，根据后端需求是否序列化
        if (
          config.method.toLocaleLowerCase() === 'post' ||
          config.method.toLocaleLowerCase() === 'put' ||
          config.method.toLocaleLowerCase() === 'delete'
        ) {
          //config.data = qs.stringify(config.data);  //暂时注释，放开会引起上传控件报错，拿不到文件
          config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        }
        let token = sessionStorage.getItem('token')
        if (token) {
          config.headers['Authorization'] = token
        }

        // 添加cookie
        let m = localStorage.getItem('x-custom-header');
        if (m && m != undefined) {
          config.headers['X-Custom-Header'] = m
        }

        //添加渠道
        let channel = getUrlParams('channel') || sessionStorage.getItem('origin-channel');
        console.log(channel)
        if (channel && channel != undefined) {
          config.headers['Origin-Channel'] = channel
        }

        return config
      },

      (error) => {
        // 1.判断请求超时
        if (
          error.code === 'ECONNABORTED' &&
          error.message.indexOf('timeout') !== -1
        ) {
          Util.Info({
            message: data.msg || '判断请求现在超时了',
          })
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      },
    )

    /**
     * response 拦截器
     * 对返回数据做统一处理
     */
    instance.interceptors.response.use(
      (response) => {
        let data
        // Util.closeLoading();
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)

        let m = localStorage.getItem('x-custom-header');
        let n = response.headers['x-custom-header']
        console.log(m)
        if (n) {

          if (!m) {
            localStorage.setItem('x-custom-header', response.headers['x-custom-header'])
          }

          if (m && m != response.headers['x-custom-header'] && m != undefined) {
            localStorage.setItem('x-custom-header', response.headers['x-custom-header'])
          }

        }

        if (response.data == undefined) {
          data = response.request
            ? JSON.parse(response.request.responseText)
            : ''
        } else {
          data = JSON.parse(response.data)
        }

        // 根据返回的code值来做不同的处理（和后端约定）
        /* if (data.code && data.code != 200 && data.code != 205 && data.code != 2005) {
          Util.Info({
            message: data.message || data.msg || '出错了，请联系管理员'
          })
        }; */
        switch (data.code) {
          case '0':
            //请求成功，不做处理
            break
          case 103:
            //特殊定义，不做处理
            break
          case 603:
            //登录失效
            //sessionStorage.removeItem('token')
            //sessionStorage.removeItem('cookie')
            //window.location.href = '/'
            break
          case 205:
            //特殊定义，不做处理
            break
          case 2005:
            //特殊定义，不做处理
            break
          case 200:
            //特殊定义，不做处理
            break
          case undefined:
            break
          case '-1':
            if (data.errId == '-200') {
              location.reload()
            } else {
              Util.Info({
                message: data.message || data.msg || '出错了，请联系管理员',
              })
            }
            break
          default:
            Util.Info({
              message: data.message || data.msg || '出错了，请联系管理员',
            })

            break
        }

        return data
      },
      (err) => {
        //alert(JSON.stringify(err));
        // Util.closeLoading();
        if (err && err.response) {
          switch (err.response.status) {
            case 400:
              err.message = '请求错误'
              break
            case 401:
              err.message = '未授权，请登录'
              break
            case 403:
              err.message = '拒绝访问'
              break
            case 404:
              err.message = `请求地址出错: ${err.response.config.url}`
              break
            case 408:
              err.message = '请求超时'
              break
            case 500:
              err.message = '服务器内部错误'
              break
            case 501:
              err.message = '服务未实现'
              break
            case 502:
              err.message = '网关错误'
              break
            case 503:
              err.message = '服务不可用'
              break
            case 504:
              err.message = '网关超时'
              break
            case 505:
              err.message = 'HTTP版本不受支持'
              break
            case 606:
              err.message = '未登录或登录超时。请重新登录'
              break
            default:
          }
        }
        console.log('错误代码：' + err.message)
        Util.Info({
          message: err.message || '出错了，请联系管理员',
        })
        return Promise.reject(err) // 返回接口返回的错误信息
      },
    )

    //请求处理
    instance(options)
      .then((res) => {
        resolve(res)
        return false
      })
      .catch((error) => {
        reject(error)
      })
  })
}
