import axios from './api' // 倒入 api
import config from './config.js' // 倒入默认配置
export const BASEURL = config.baseURL
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 */

// 获取活动详情
export const GET_ACTIVITY_INFO = (params) => {
  return axios({
    url: '/h5/activity/info',
    method: 'GET',
    params,
  })
}

/** 获取城市列表 */
export const SAVE_ACTIVITY_INFO = (params) => {
  return axios({
    url: '/h5/activity/submit',
    method: 'POST',
    data: params,
  })
}

// 埋点接口
export const SAVEMINEPOINTUPV = (params) => {
  return axios({
    url: '/minepoint/upv',
    method: 'POST',
    data: params,
  })
}