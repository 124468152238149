import {
  Toast,
  Notify,
  Dialog
} from 'vant';
export const Util = {

  /**
   * 简单alert
   * title 标题
   * message 内容
   * className 自定义class
   * showCancelButton 是否展示取消按钮
   * confirmButtonText 确认按钮文案
   * cancelButtonText 取消按钮文案
   * confirmButtonColor 确认按钮颜色
   * cancelButtonColor 取消按钮颜色
   */
  msg: function (options) {
    Dialog({
      title: options.title || '',
      message: options.message || '',
      className: options.className || '',
      showCancelButton: options.showCancelButton || false,
      confirmButtonText: options.confirmButtonText || '确认',
      cancelButtonText: options.cancelButtonText || '取消',
      confirmButtonColor: options.confirmButtonColor || '#1989fa',
      cancelButtonColor: options.cancelButtonColor || '#000',
      beforeClose: function (e, done) {
        if (e == 'confirm') {
          options.beforeClose ? (options.beforeClose)(done) : done();
        } else {
          done();
        }
      }
    });
  },

  /**
   * 提示框
   */
  Info: function (options) {
    Notify({
      message: options.message || '提示',
      duration: options.time || 2000,
      background: options.background || '#f44'
    });
  },

  /**
   * 轻提示
   */
  Tips: function (options) {
    Toast({
      message: options.message || '提示',
      duration: options.duration || 3000,
      type: options.type || 'text',
      icon: options.icon || ''
    })
  },

  /**
   * loading
   */
  showLoading: function (message) {
    Toast.loading({
      mask: true,
      message: message || '加载中...'
    });
  },

  /**
   * 关闭loading
   */
  hideLoading: function () {
    Toast.clear();
  },

  /**
   * 校验手机号码格式
   */
  checkPhone: function (mobile) {
    var myreg = /^(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[0-9])[0-9]{8}$/;
    return myreg.test(mobile);
  },

  /**
   * 是否特殊字符
   */
  isSpecialLetter: function (str) {
    var reg = new RegExp("[`~!@#$^&*=|{}':;',\\[\\]<>?~！@#￥……&*——|{}【】‘；：”“'。，、？ ]");
    return reg.test(str);
  },

  /**
   * 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
   */
  isCardId: function (sId) {
    let iSum = 0;
    let sBirthday = '';
    let aCity = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外'
    };
    if (!/^\d{17}(\d|x)$/i.test(sId)) {

      //return "你输入的身份证长度或格式错误";
      return false;
    }
    sId = sId.replace(/x$/i, 'a');
    if (aCity[parseInt(sId.substr(0, 2))] == null) {

      //return "你的身份证地区非法";
      return false;
    }
    sBirthday = sId.substr(6, 4) + '-' + Number(sId.substr(10, 2)) + '-' + Number(sId.substr(12, 2));
    let d = new Date(sBirthday.replace(/-/g, '/'));
    if (sBirthday != (d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate())) {

      //return "身份证上的出生日期非法";
      return false;
    }
    for (let i = 17; i >= 0; i--) {
      iSum += (Math.pow(2, i) % 11) * parseInt(sId.charAt(17 - i), 11);
    }
    if (iSum % 11 != 1) {

      //return "你输入的身份证号非法";
      return false;

      //aCity[parseInt(sId.substr(0,2))]+","+sBirthday+","+(sId.substr(16,1)%2?"男":"女");
      //此次还可以判断出输入的身份证号的人性别
    }
    return true;
  },



  /**
   * 获取浏览器参数
   */
  getUrlParams: function (name) {
    var vars = [],
      hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    let o = '';
    if (name) {
      o = vars[name]
    } else {
      o = vars
    }
    return o;
  },

  /**
   * 深克隆 所有原型链上的对象和属性
   * 例: const bar=deepClone(foo)
   * console.log(bar===foo) false
   * @param {*} obj
   */
  deepClone: function (obj) {
    // Handle the 3 simple types, and null or undefined or function
    if (obj == null || typeof obj !== 'object') return obj
    // Handle Date
    if (obj instanceof Date) {
      const copy = new Date()
      copy.setTime(obj.getTime())
      return copy
    }
    // Handle Array or Object
    if ((obj instanceof Array) | (obj instanceof Object)) {
      const copy = obj instanceof Array ? [] : {}
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = Util.deepClone(obj[attr])
        }
      }
      return copy
    }
    throw new Error("Unable to clone obj! Its type isn't supported.")
  },

};

// 获取设备类型
export const getDevinceType = (params) => {
  let ios = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
  if (ios) {
    return 'ios'
  } else {
    return 'android'
  }
}

export const getUrlParams = Util.getUrlParams;
export const checkPhone = Util.checkPhone;
export const showToast = Util.Tips;
export const showModal = Util.msg;
export const showLoading = Util.showLoading;
export const hideLoading = Util.hideLoading;
export const deepClone = Util.deepClone