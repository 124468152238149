import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  //base: "v40",
  routes: [
    {
      path: "/",
      name: "index",
      meta: "首页",
      component: () => import("@/views/activity/index"),
    },
    {
      path: "/result",
      name: "result",
      meta: "提交成功",
      component: () => import("@/views/activity/result"),
    },
  ],
});
